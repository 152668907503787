import axios from "axios"

const BACKOFFICE_ENDPOINT = process.env.GATSBY_BACKOFFICE_ENDPOINT
const BACKOFFICE_API_KEY = process.env.GATSBY_BACKOFFICE_API_KEY
const STRIPE_ENDPOINT = process.env.GATSBY_STRIPE_ENDPOINT

export const axiosGetRequest = async (type, value) => {
  return axios.get(
    `${BACKOFFICE_ENDPOINT}/${
      type === "checkDomain" ? "instance-check" : "provision-instance"
    }/${value}`,
    {
      headers: {
        "api-key": BACKOFFICE_API_KEY,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
}

export const axiosBackOfficePostRequest = async value => {
  return axios.post(`${BACKOFFICE_ENDPOINT}/provision-instance`, value, {
    headers: {
      "api-key": BACKOFFICE_API_KEY,
      "Access-Control-Allow-Origin": "*",
    },
  })
}

export const axiosCheckoutSessionRequest = async (session_id, api_key) => {
  return axios.get(`${STRIPE_ENDPOINT}/${session_id}`, {
    headers: {
      Authorization: `Bearer ${api_key}`,
    },
  })
}

export const axiosStripePostRequest = async value => {
  return axios.post(`${BACKOFFICE_ENDPOINT}/stripe-session`, value, {
    headers: {
      "api-key": BACKOFFICE_API_KEY,
      "Access-Control-Allow-Origin": "*",
    },
  })
}
