import React from "react"
import "../../styles/components/global/block--loader.scss"
import FieldText from "../field/fieldText"

const Loader = ({ loaderMsg = "", classes }) => {
  return (
    <div className={`block--loader ${classes ? classes : ""}`}>
      <div className="loader__dots">
        <div className="loader__dot loader__dot--first"></div>
        <div className="loader__dot loader__dot--second"></div>
        <div className="loader__dot loader__dot--third"></div>
        <div className="loader__dot loader__dot--fourth"></div>
      </div>

      {loaderMsg && (
        <FieldText>
          <h1 className="text text--centered">{loaderMsg}</h1>
        </FieldText>
      )}
    </div>
  )
}

export default Loader
